import './customer.scss'
import QrComponent from '../../components/QrComponent'
import CustomerComponent from '../../components/CustomerComponent'
import { useState, useEffect, useContext } from 'react'
import PngIcons from '../../assets/images/pngIcons'
import PhoneComponent from '../../components/PhoneComponent'
import EmailComponent from '../../components/EmailComponent'
import localforage from 'localforage'
import { useNavigate } from 'react-router-dom'
import { utils } from '../../utils'
import { UserContext } from '../../context/User.context'
import SvgIcons from '../../assets/images/svgIcons'


export default function CustomerPage() {

  const user = useContext(UserContext)

  


  const selectionType = [
    // {
    //   title: 'Scanner',
    //   description: 'Scan customer qr code to take action.',
    //   component: <QrComponent />,
    //   image: PngIcons.Scanner
    // },
    {
      title: 'Phone Number',
      description: 'Enter customer phone number to take action.',
      image: SvgIcons.One,
      component: <PhoneComponent />
    },
    {
      title: 'Customer Email',
      description: 'Enter customer email to take action.',
      image: SvgIcons.Three,
      component: <EmailComponent />
    }
  ]


const [media, setMedia] = useState(false);
const [reward, setReward] = useState(false);
const [brand, setBrand] = useState();



  const [selectedComponent, setSelectedComponent] = useState(selectionType[0])

  const handleSelectFuc = selection => {
    setSelectedComponent(selection)
  }

  function onClickHandler() {
    utils.Logout();
  }
  const history = useNavigate();

//   const x = window.matchMedia("(max-width: 700px)")

// if(x.matches){
//   setMedia(true);
// }

// useEffect(() => {
  
// }, [x,media])

  useEffect(() => {

    checkFlows();

  }, [])
  

  const checkFlows = async ()=>{

    let brands = await localforage.getItem("brands");
    let selectedBrand = await localforage.getItem("selectedBrand");

    console.log('brands',brands);
    console.log('selectedBrand',selectedBrand);
    
    
    if(brands.length===1) setBrand(brands[0])
    else setBrand(selectedBrand)

    let check = await localforage.getItem("flows");
    console.log("Check Flows",check);
    if(!check){
      // console.log("value",check)
      setReward(true);
    }
    else{
      setReward(false);
    }
    console.log("reward",reward);
  }

  return (
    <div className='w-95 main-box-res' style={{ height: '100vh' , margin : '0 auto ' }} >
      {console.log('brand',brand)}
      
      <div style={{ height: '15%' , display : 'flex' , flexDirection : 'column',justifyContent : 'center'}} className='header-bg-res res-white res-padding'>
        {/* <div className='body-font res-white'>Logged in by:</div> */}
        <div style={{display : 'flex' , justifyContent : 'space-between'}}>
          <div className='d-flex align-items-center ' style={{gap: '18px'}}>
            <div><img className='borderRadius-50' src={brand?.brandLogo} alt="Brand Logo" width="42px" height="42px" /></div>
            {/* <div  style={{color: '#212529',fontFamily: 'Inter',fontSize: '14px',fontStyle: 'normal',fontWeight: '600',lineHeight: '16.8px',textTransform: 'capitalize'}}>{user && user.firstName + ' ' + user.lastName}</div> */}
            <div  style={{color: '#212529',fontFamily: 'Inter',fontSize: '14px',fontStyle: 'normal',fontWeight: '600',lineHeight: '16.8px',textTransform: 'capitalize'}}>{brand?.brandName}</div>
          </div>
          <div style={{display : 'flex'}}>
          <div>
          <img
            className='login-black'
            src={PngIcons.Bell}
            alt=''
            style={{cursor : 'pointer'}}
            onClick={onClickHandler}
            width= '28px'
            height = '28px'
          />
          <img
            className='login-white'
            src={PngIcons.PowerWhite}
            alt=''
            style={{cursor : 'pointer'}}
            onClick={onClickHandler}
            width= '28px'
            height = '28px'
          />

        </div>
       
          </div>
        </div>
       
      </div>


   
      <div style={{ height: '85%' }}>
        <div className=' d-flex justify-content-center customer responsive-boxes'>
          {selectionType.map(selection => (
            <div
              onClick={() => handleSelectFuc(selection)}
              // className={`cardBox cp d-flex ${selectedComponent.title ===
              //   selection.title && 'selected p-auto'}`}
            >
              {/* <div className='mr-15 middle'> */}
                {/* <img
                  src={selection.image}
                  alt=''
                  height={20}
                  width={20}
                  className='icon'
                ></img> */}
                <selection.image selected={selectedComponent.title===selection.title?true:false}/>
              {/* </div> */}
              {/* <div>
                <div className='scanner'>{selection.title}</div>
                <div className='sub-text'>{selection.description}</div>
              </div> */}
            </div>
          ))}
        </div>

        <div>
          <h1>{selectedComponent.component}</h1>
        </div>
      </div>
    




    </div>





  )
}
